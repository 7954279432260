<template>
  <div>
    <div class="page_wrap">
      <div class="form_div">
        <div class="text_header" style="text-align: left">
          Setup your profile
        </div>
        <div class="skill_set">
          Review details and confirm. Untick the checkbox to remove any
          information you do no want on your Grupa profile.
        </div>
        <div class="section-header" style="margin-top: 79px">
          Profile Picture
        </div>
        <div class="content-box" style="margin-bottom: 48px">
          <div class="check-info">
            <img src="/img/onboarding/checked-box.svg" />
          </div>
          <div class="profile-image">
            <canvas id="myCanvas" width="230" height="230"> </canvas>
            <!-- <v-avatar
              v-if="user_profile.profile_pic_url"
              size="230"
              color="grey lighten-4"
              class="profile_bg"
              :style="{
                backgroundImage: `url(${
                  new_picture ? new_picture : user_profile.profile_pic_url
                })`,
              }"
            >
              <input
                type="file"
                accept="image/*"
                name="somename"
                size="chars"
                class="input_file"
                @change="onFileChange"
              />
              <div class="btn-knife" @click="openFile()">
                <img src="/img/onboarding/edit-image.svg " />
              </div>
            </v-avatar> -->
          </div>
        </div>
        <hr />
        <div class="section-header">Experience</div>
        <div
          class="content-box"
          v-for="(experience, j) in user_profile.experiences"
          :key="j"
        >
          <div class="content-wrapper">
            <div class="work-details">
              <div
                class="icon-div"
                @click="handleToggleSelected(experience.id, 'experiences')"
              >
                <img
                  :src="`/img/onboarding/client/${
                    experience.selected ? 'checked' : 'unchecked'
                  }.svg`"
                />
              </div>
              <div class="details-container">
                <div class="experience-role">{{ experience.title }}</div>
                <div class="company-name">{{ experience.company }}</div>
                <div class="exprience-date">
                  {{ experience.starts_at ? experience.starts_at.year : "" }}
                  {{
                    experience.starts_at
                      ? monthName(experience.starts_at.month)
                      : ""
                  }}

                  -
                  {{
                    experience.ends_at ? experience.ends_at.year : "Till date"
                  }}
                  {{
                    experience.ends_at
                      ? monthName(experience.ends_at.month)
                      : ""
                  }}
                </div>
                <div class="work-location">
                  {{ experience.location ? experience.location : "" }}
                </div>
              </div>
            </div>
            <div v-if="experience.logo_url" class="company-logo">
              <div
                class="logo-background"
                :style="{
                  backgroundImage: `url(${experience.logo_url})`,
                }"
              ></div>
              <!-- <img :src="experience.logo_url" alt="Logo" /> -->
            </div>
          </div>
        </div>
        <hr style="margin-top: 48px" />
        <div class="section-header">Education</div>
        <div
          class="content-box"
          v-for="school in user_profile.education"
          :key="school.degree_name"
        >
          <div
            class="icon-div"
            @click="handleToggleSelected(school.id, 'education')"
          >
            <img
              :src="`/img/onboarding/client/${
                school.selected ? 'checked' : 'unchecked'
              }.svg`"
            />
          </div>
          <div class="details-container">
            <div class="degree-type">
              {{ school.degree_name }}, {{ school.field_of_study }}
            </div>
            <div class="school-name">{{ school.school }}</div>
            <div class="exprience-date">
              {{ school.starts_at ? school.starts_at.year : "" }}
              -
              {{ school.ends_at ? school.ends_at.year : "" }}
            </div>
          </div>
        </div>
        <hr style="margin-top: 48px" />
        <div class="section-header">Other details</div>
        <div class="content-box">
          <div class="icon-div">
            <img src="/img/onboarding/checked-box.svg" />
          </div>
          <div class="details-container">
            <div class="skill-title">Languages</div>
            <div
              v-if="user_profile.languages.length > 0"
              class="skils-list"
              v-for="(language, j) in user_profile.languages"
              :key="j"
            >
              <div class="skill-box">
                {{ language.language ? language.language : language }}
              </div>
            </div>
          </div>
        </div>
        <div class="content-box">
          <div class="icon-div">
            <img src="/img/onboarding/checked-box.svg" />
          </div>
          <div class="details-container">
            <div class="degree-type">Location</div>
            <div v-if="user_profile.country_full_name" class="exprience-date">
              {{ user_profile.country_full_name }}
            </div>
          </div>
        </div>
        <hr style="margin-top: 48px" />

        <div class="content-box" style="margin-top: 0px">
          <div class="details-container" style="flex-grow: 1">
            <div class="skill-title">Team Role</div>

            <div class="error-container">
              <span v-if="!form.team_role">
                {{ form_errors.team_role[0] }}
              </span>
            </div>

            <v-flex xs12>
              <v-select
                class="vue_select"
                v-model="form.team_role"
                label="What role will you be playing in the team"
                :items="teamRoleArray"
                return-object
                solo
                flat
                light
                item-text="name"
                item-value="id"
              ></v-select>
            </v-flex>
          </div>
        </div>
        <div v-if="enableToolsInput">
          <div class="error-container" style="margin-top: 24px">
            <!-- <span v-if="!form.language_tools">
              {{ form_errors.framework_tools[0] }}
            </span> -->
          </div>
          <div class="content-box" style="margin-top: 0px">
            <div class="details-container" style="flex-grow: 1">
              <div class="skill-title">Tools & Technologies</div>
              <v-flex xs12>
                <v-combobox
                  class="vue_select"
                  :items="all_tools"
                  v-model="toolLanguages"
                  item-value="name"
                  item-text="name"
                  label="Type or select tech stack"
                  multiple
                  persistent-hint
                  small-chips
                  solo
                  flat
                  id="selectedCombo"
                  @change="updateStack"
                ></v-combobox>
              </v-flex>
              <!-- @keypress="handleComma($event)" -->
            </div>
          </div>
        </div>

        <div class="form-grouped">
          <div class="div-label">
            How did you hear about us?

            <div class="error-container">
              <span v-if="!form.hear_about_us">
                {{ form_errors.hear_about_us[0] }}
              </span>
            </div>
          </div>
          <div class="light-label">
            We would like to know how you heard about Grupa.
          </div>

          <div class="chip-btn-container" v-if="isLoadingHearAboutUs == true">
            <img
              src="/img/lightbox/preloader.gif"
              style="margin-left: 10px"
              class="button-spinner"
            />
          </div>

          <div class="chip-btn-container" v-if="isLoadingHearAboutUs == false">
            <!-- 
              :buttonIcon="'img/onboarding/specify_preferences/' + item.img"
           -->
            <ChipButton
              v-for="(item, index) in getHearAboutUsArray"
              :key="index"
              :text="item.name"
              :dashedBorder="true"
              :selected="checkedSelectedHearAboutUs(item.id)"
              :buttonIcon="'img/onboarding/specify_preferences/' + item.img"
              v-on:click="handleSelectHearAboutUs(item.id)"
            />
          </div>
        </div>
        <!-- End of user profile -->
        <div class="btn_div">
          <button class="grupa-blue-btn" @click="handleSubmitData">
            Confirm
            <img
              src="/img/lightbox/preloader.gif"
              style="margin-left: 20px"
              class="button-spinner"
              v-show="spinner"
            />
          </button>
        </div>
      </div>
    </div>
    <AlertError v-if="errorMsg" time="7" />

    <NewHearAboutUsVue
      v-bind="{
        getHearAboutUsArray,
        toggleModal,
        toggleFun,
        finishSaveToList,
      }"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import {
  devWorkExperience,
  addToolsAndFramework,
  editDevImage,
  devAddPortfolioLinks,
  devAddHowYouHeardAboutUs,
} from "@/api";
import { devAddEducation } from "@/api/team";
import mixPanel from "@/mixins/mixPanel";
import AlertError from "@/components/alertError";
import ChipButton from "@/components/chipButton/chipButton";
import { getHearAboutUs } from "@/api/client";
import NewHearAboutUsVue from "./NewHearAboutUs.vue";

export default {
  mixins: [mixPanel],
  props: {
    all_tools: {
      type: Array,
      required: true,
    },
    teamRoleArray: {
      type: Array,
      required: true,
    },
    user_profile: {
      required: true,
    },
    handleToggleSelected: {
      required: true,
      type: Function,
    },
  },
  data: () => ({
    selectedRole: "",
    url: "/img/dashboard/client/team3.png",
    spinner: false,
    toolLanguages: [],
    form: {
      language_tools: "",
      team_role: "",
      spoken_lang: [],
      user_location: "",
      bio: "",
      hear_about_us: "",
    },
    new_picture: null,
    imageLink: null,
    form_errors: {
      framework_tools: [],
      team_role: [],
      hear_about_us: [],
    },
    userProfilePic: "",
    getHearAboutUsArray: [],
    isLoadingHearAboutUs: true,
    toggleModal: false,
  }),
  mounted() {
    this.downloadProfile();
  },
  created() {
    getHearAboutUs()
      .then((data) => {
        let response = data.data.data;
        this.isLoadingHearAboutUs = false;
        for (var i = 0; i < response.length; i++) {
          this.getHearAboutUsArray.push({
            name: response[i].name,
            status: response[i].status,
            id: response[i].id,
            value: response[i].id,
            img: `list_icon.svg`,
          });
        }
      })
      .catch((error) => {
        // alert('error--')
        this.isLoadingHearAboutUs = false;
        console.log(error);
      });
  },
  components: {
    AlertError,
    ChipButton,
    NewHearAboutUsVue,
  },
  computed: {
    ...mapState("userManagement", ["currentUser", "userLocation"]),
    ...mapState("devOnboarding", ["userLinkedinURL"]),
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
    enableToolsInput() {
      const role = this.form.team_role;
      /*  if (
         role == "Software Engineer - Frontend" ||
         role == "Software Engineer - Backend" ||
         role == "Software Engineer - FullStack" ||
         role == "Product Designer"
       ) {
         return true;
       } else {
         return false;
       } */
      return true;
    },

    checkedSelectedHearAboutUs() {
      return (value) => {
        let selected = this.form.hear_about_us;

        return value === selected ? true : false;
      };
    },
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    toggleFun() {
      this.toggleModal = !this.toggleModal;
    },
    finishSaveToList(data) {
      this.getHearAboutUsArray = data;
    },

    downloadProfile() {
      var canvas = document.getElementById("myCanvas");
      var context = canvas.getContext("2d");
      var img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        let width = 230;
        let height = 230;
        canvas.width = width;
        canvas.height = height;
        context.drawImage(img, 0, 0, width, height);
        var imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        //document.body.appendChild(canvas); //picture gets uploaded
        var base64Picture = canvas.toDataURL("image/png");

        // console.log(base64Picture, "Picture.... ");
        // this.userProfilePic = base64Picture;

        new Promise((resolve, reject) => {
          canvas.toBlob((file) => {
            resolve(file);
            //   resolve(URL.createObjectURL(file));
          }, "image/jpeg");
        }).then((response) => {
          // console.log(response, "blob...");
          this.userProfilePic = response;
        });
        // Pic = Pic.replace(/^data:image\/(png|jpg);base64,/, "");
      };
      // Generate the image data
      let googleProxyURL =
        "https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=";
      let imageURL = this.user_profile.profile_pic_url;
      img.crossOrigin = "Anonymous";
      img.src = googleProxyURL + encodeURIComponent(imageURL);
      // console.log(this.user_profile.profile_pic_url);
    },
    monthName(num) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return monthNames[num];
    },
    updateStack() {
      var t = [];
      this.toolLanguages.forEach((element) => {
        if (typeof element == "object") {
          t.push(element.name);
        } else if (typeof element == "string") {
          t.push(element);
        }
      });
      this.form.language_tools = t;
      let el = document.getElementById("selectedCombo");
      var e = new KeyboardEvent("keydown", {
        bubbles: true,
        cancelable: true,
        char: "Escape",
        key: "Escape",
        shiftKey: true,
        keyCode: 27,
      });
      el.dispatchEvent(e);
    },
    handleComma(event) {
      if (event.key == "," || event.key == " ") {
        event.preventDefault();
        let el = document.getElementById("selectedCombo");
        var e = new KeyboardEvent("keydown", {
          bubbles: true,
          cancelable: true,
          char: "Tab",
          key: "Tab",
          shiftKey: true,
          keyCode: 9,
        });
        el.dispatchEvent(e);
      } else {
        event;
      }
    },
    handleSubmitData() {
      let { language_tools, team_role, hear_about_us } = this.form;
      this.form_errors = {
        // framework_tools: [],
        team_role: [],
        hear_about_us: [],
      };
      // if (!language_tools) {
      //   this.form_errors.framework_tools[0] = "Select tools and frameworks";
      // }
      if (!team_role) {
        this.form_errors.team_role[0] = "Select your role in the team";
        this.setErrorMsg(
          "One or more required field was not filled out properly"
        );
      }

      if (!hear_about_us) {
        this.form_errors.hear_about_us[0] = "Select how you heard about us";
        this.setErrorMsg(
          "One or more required field was not filled out properly"
        );
      }
      if (team_role && hear_about_us) {
        this.spinner = true;
        this.uploadProfileImage();
        this.saveLinkedInUrl();
        this.SaveHearAboutUs();
        this.saveFrameworksAndLanguages();
        this.saveEducation();
        this.saveWorkExperience();
      }
    },
    SaveHearAboutUs() {
      const payload = {
        hear_about_us: this.form.hear_about_us,
      };

      devAddHowYouHeardAboutUs(payload).then((data) => {});
    },
    saveLinkedInUrl() {
      const payload = {
        linkedin_profile: this.userLinkedinURL,
      };
      if (this.userLinkedinURL) {
        devAddPortfolioLinks(payload);
      }
    },
    saveWorkExperience() {
      let { experiences } = this.user_profile;
      let payload = experiences
        .filter((experience) => experience.selected)
        .map((work) => {
          return {
            role: work.title,
            organization: work.company,
            company_location: work.location,
            website: work.company_linkedin_profile_url
              ? work.company_linkedin_profile_url
              : "https://www.linkedin.com",
            start_date: work.starts_at ? this.formatDate(work.starts_at) : "",
            end_date: work.ends_at ? this.formatDate(work.ends_at) : "",
            current_job: work.ends_at ? false : true,
            company_logo: work.logo_url,
          };
        });
      let work_experiece = { work_experience: payload };
      devWorkExperience(work_experiece)
        .then((response) => {
          if (response.data.status == 1) {
          }
          // this.$router.push({ name: "dev_dashboard" });
        })
        .catch((err) => {
          this.mixPanelTrack({
            event: "WorkExperienceFailed",
            email: this.currentUser.email,
          });
        });
    },
    saveEducation() {
      let { education } = this.user_profile;
      let payload = education
        .filter((sch) => sch.selected)
        .map((sch) => {
          return {
            degree_title: sch.degree_name,
            institution: sch.school,
            location: "https://www.school.com",
            start_date: sch.starts_at ? this.formatDate(sch.starts_at) : "",
            end_date: sch.ends_at ? this.formatDate(sch.ends_at) : "",
          };
        });
      devAddEducation(payload)
        .then((response) => {
          if (response.data.status == 1) {
          }
        })
        .catch((err) => {});
    },
    uploadProfileImage() {
      const uploadImage = this.userProfilePic;
      let payload;
      if (uploadImage) {
        payload = new FormData();
        payload.append("profile_picture", uploadImage);
        // let payload = { profile_picture: this.userProfilePic };
      }
      editDevImage(payload)
        .then((response) => {
          if (response.data.status == 1) {
          }
        })
        .catch((errr) => {
          errr;
          errr;
          console.log(errr);
        });
    },
    saveFrameworksAndLanguages() {
      // currentUser
      const { languages, country_full_name, summary } = this.user_profile;
      let userLocation = country_full_name
        ? country_full_name
        : this.userLocation.country_name;
      this.form.spoken_lang = languages;
      this.form.user_location = userLocation;
      (this.form.bio = summary ? summary : ""),
        addToolsAndFramework(this.form)
          .then((response) => {
            if (this.currentUser.team_lead == 1) {
              this.$router.push({ name: "team_interests" });
            } else {
              this.$router.push({ name: "dev_dashboard" });
            }
            this.mixPanelTrack({
              event: "SaveLinkedinProfile",
              email: this.currentUser.email,
            });
          })
          .catch((error) => {});
    },
    formatDate(date) {
      let { day, month, year } = date;
      return `${year}-${month}-${day}`;
    },
    openFile() {
      document.querySelector(".input_file").click();
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.new_picture = URL.createObjectURL(file);
      this.userProfilePic = e.target.files[0];
    },

    handleSelectHearAboutUs(value) {
      this.form.hear_about_us = value;
      this.form_errors.hear_about_us = [];
      if (value == 6) {
        this.toggleFun();
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

.indicator_progress {
  display: flex;
  justify-content: center;
}

.page_wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.form_div {
  width: 410px;
  text-align: left;
  /* padding-bottom: 150px; */
}

.div-label {
  text-align: left;
  font-size: 14px;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 145%;
}

.chip-btn-container {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 8px;
}

.form-grouped {
  margin-top: 48px;
}

.light-label {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 8px;
  text-align: left;
}
.text_header {
  margin-bottom: 16px;
  margin-top: 65px;
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
}

.skill_set {
  font-size: 16px;
  line-height: 130%;
  color: #979da5;
  text-align: initial;
}

.section-header {
  margin-top: 48px;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #15171a;
}

.content-box {
  display: flex;
  margin-top: 24px;
}

.error-container {
  height: 20px;
  color: red;
  margin-top: 4px;
  /* text-align: center; */
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.work-details {
  display: flex;
}

.profile_bg {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: top;
}

.input_file {
  display: block;
  visibility: hidden;
  width: 0;
  height: 0;
}

.btn-knife {
  position: absolute;
  top: 81%;
  left: 87%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.check-info {
  height: 227px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}

.details-container {
}

.skill-title,
.company-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #15171a;
}

.skils-list {
  display: flex;
  margin-top: 4px;
  flex-wrap: wrap;
}

.skill-box {
  background: #f7f7f8;
  border-radius: 30px;
  padding: 4px 8px;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
  margin-right: 8px;
  margin-top: 8px;
}

.icon-div {
  margin-right: 24px;
}

.experience-role,
.degree-type,
.school-name,
.degree-field {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #15171a;
  margin-bottom: 2px;
}

.work-location,
.exprience-date {
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 4px;
}

.company-logo {
  width: 80px;
  height: 32px;
}

.logo-background {
  background-size: contain;
  width: 100%;
  height: 100%;
  background-position: top;
}

.btn_div {
  margin-top: 88px;
  display: flex;
  justify-content: flex-end;
}

.vue_select {
  margin-top: 12px;
}

@media (max-width: 768px) {
  .btn_div {
    justify-content: flex-start;
  }

  .form_div {
    width: unset;
    padding: 0 10px;
  }
}
</style>
