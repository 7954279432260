var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "page_wrap" }, [
        _c(
          "div",
          { staticClass: "form_div" },
          [
            _c(
              "div",
              {
                staticClass: "text_header",
                staticStyle: { "text-align": "left" },
              },
              [_vm._v("\n        Setup your profile\n      ")]
            ),
            _c("div", { staticClass: "skill_set" }, [
              _vm._v(
                "\n        Review details and confirm. Untick the checkbox to remove any\n        information you do no want on your Grupa profile.\n      "
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "section-header",
                staticStyle: { "margin-top": "79px" },
              },
              [_vm._v("\n        Profile Picture\n      ")]
            ),
            _vm._m(0),
            _c("hr"),
            _c("div", { staticClass: "section-header" }, [
              _vm._v("Experience"),
            ]),
            _vm._l(_vm.user_profile.experiences, function (experience, j) {
              return _c("div", { key: j, staticClass: "content-box" }, [
                _c("div", { staticClass: "content-wrapper" }, [
                  _c("div", { staticClass: "work-details" }, [
                    _c(
                      "div",
                      {
                        staticClass: "icon-div",
                        on: {
                          click: function ($event) {
                            return _vm.handleToggleSelected(
                              experience.id,
                              "experiences"
                            )
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src:
                              "/img/onboarding/client/" +
                              (experience.selected ? "checked" : "unchecked") +
                              ".svg",
                          },
                        }),
                      ]
                    ),
                    _c("div", { staticClass: "details-container" }, [
                      _c("div", { staticClass: "experience-role" }, [
                        _vm._v(_vm._s(experience.title)),
                      ]),
                      _c("div", { staticClass: "company-name" }, [
                        _vm._v(_vm._s(experience.company)),
                      ]),
                      _c("div", { staticClass: "exprience-date" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              experience.starts_at
                                ? experience.starts_at.year
                                : ""
                            ) +
                            "\n                " +
                            _vm._s(
                              experience.starts_at
                                ? _vm.monthName(experience.starts_at.month)
                                : ""
                            ) +
                            "\n\n                -\n                " +
                            _vm._s(
                              experience.ends_at
                                ? experience.ends_at.year
                                : "Till date"
                            ) +
                            "\n                " +
                            _vm._s(
                              experience.ends_at
                                ? _vm.monthName(experience.ends_at.month)
                                : ""
                            ) +
                            "\n              "
                        ),
                      ]),
                      _c("div", { staticClass: "work-location" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              experience.location ? experience.location : ""
                            ) +
                            "\n              "
                        ),
                      ]),
                    ]),
                  ]),
                  experience.logo_url
                    ? _c("div", { staticClass: "company-logo" }, [
                        _c("div", {
                          staticClass: "logo-background",
                          style: {
                            backgroundImage: "url(" + experience.logo_url + ")",
                          },
                        }),
                      ])
                    : _vm._e(),
                ]),
              ])
            }),
            _c("hr", { staticStyle: { "margin-top": "48px" } }),
            _c("div", { staticClass: "section-header" }, [_vm._v("Education")]),
            _vm._l(_vm.user_profile.education, function (school) {
              return _c(
                "div",
                { key: school.degree_name, staticClass: "content-box" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "icon-div",
                      on: {
                        click: function ($event) {
                          return _vm.handleToggleSelected(
                            school.id,
                            "education"
                          )
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src:
                            "/img/onboarding/client/" +
                            (school.selected ? "checked" : "unchecked") +
                            ".svg",
                        },
                      }),
                    ]
                  ),
                  _c("div", { staticClass: "details-container" }, [
                    _c("div", { staticClass: "degree-type" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(school.degree_name) +
                          ", " +
                          _vm._s(school.field_of_study) +
                          "\n          "
                      ),
                    ]),
                    _c("div", { staticClass: "school-name" }, [
                      _vm._v(_vm._s(school.school)),
                    ]),
                    _c("div", { staticClass: "exprience-date" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            school.starts_at ? school.starts_at.year : ""
                          ) +
                          "\n            -\n            " +
                          _vm._s(school.ends_at ? school.ends_at.year : "") +
                          "\n          "
                      ),
                    ]),
                  ]),
                ]
              )
            }),
            _c("hr", { staticStyle: { "margin-top": "48px" } }),
            _c("div", { staticClass: "section-header" }, [
              _vm._v("Other details"),
            ]),
            _c("div", { staticClass: "content-box" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "details-container" },
                [
                  _c("div", { staticClass: "skill-title" }, [
                    _vm._v("Languages"),
                  ]),
                  _vm._l(_vm.user_profile.languages, function (language, j) {
                    return _vm.user_profile.languages.length > 0
                      ? _c("div", { key: j, staticClass: "skils-list" }, [
                          _c("div", { staticClass: "skill-box" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  language.language
                                    ? language.language
                                    : language
                                ) +
                                "\n            "
                            ),
                          ]),
                        ])
                      : _vm._e()
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "content-box" }, [
              _vm._m(2),
              _c("div", { staticClass: "details-container" }, [
                _c("div", { staticClass: "degree-type" }, [_vm._v("Location")]),
                _vm.user_profile.country_full_name
                  ? _c("div", { staticClass: "exprience-date" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.user_profile.country_full_name) +
                          "\n          "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("hr", { staticStyle: { "margin-top": "48px" } }),
            _c(
              "div",
              {
                staticClass: "content-box",
                staticStyle: { "margin-top": "0px" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "details-container",
                    staticStyle: { "flex-grow": "1" },
                  },
                  [
                    _c("div", { staticClass: "skill-title" }, [
                      _vm._v("Team Role"),
                    ]),
                    _c("div", { staticClass: "error-container" }, [
                      !_vm.form.team_role
                        ? _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.form_errors.team_role[0]) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("v-select", {
                          staticClass: "vue_select",
                          attrs: {
                            label: "What role will you be playing in the team",
                            items: _vm.teamRoleArray,
                            "return-object": "",
                            solo: "",
                            flat: "",
                            light: "",
                            "item-text": "name",
                            "item-value": "id",
                          },
                          model: {
                            value: _vm.form.team_role,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "team_role", $$v)
                            },
                            expression: "form.team_role",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm.enableToolsInput
              ? _c("div", [
                  _c("div", {
                    staticClass: "error-container",
                    staticStyle: { "margin-top": "24px" },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "content-box",
                      staticStyle: { "margin-top": "0px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "details-container",
                          staticStyle: { "flex-grow": "1" },
                        },
                        [
                          _c("div", { staticClass: "skill-title" }, [
                            _vm._v("Tools & Technologies"),
                          ]),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-combobox", {
                                staticClass: "vue_select",
                                attrs: {
                                  items: _vm.all_tools,
                                  "item-value": "name",
                                  "item-text": "name",
                                  label: "Type or select tech stack",
                                  multiple: "",
                                  "persistent-hint": "",
                                  "small-chips": "",
                                  solo: "",
                                  flat: "",
                                  id: "selectedCombo",
                                },
                                on: { change: _vm.updateStack },
                                model: {
                                  value: _vm.toolLanguages,
                                  callback: function ($$v) {
                                    _vm.toolLanguages = $$v
                                  },
                                  expression: "toolLanguages",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "form-grouped" }, [
              _c("div", { staticClass: "div-label" }, [
                _vm._v("\n          How did you hear about us?\n\n          "),
                _c("div", { staticClass: "error-container" }, [
                  !_vm.form.hear_about_us
                    ? _c("span", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.form_errors.hear_about_us[0]) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "light-label" }, [
                _vm._v(
                  "\n          We would like to know how you heard about Grupa.\n        "
                ),
              ]),
              _vm.isLoadingHearAboutUs == true
                ? _c("div", { staticClass: "chip-btn-container" }, [
                    _c("img", {
                      staticClass: "button-spinner",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { src: "/img/lightbox/preloader.gif" },
                    }),
                  ])
                : _vm._e(),
              _vm.isLoadingHearAboutUs == false
                ? _c(
                    "div",
                    { staticClass: "chip-btn-container" },
                    _vm._l(_vm.getHearAboutUsArray, function (item, index) {
                      return _c("ChipButton", {
                        key: index,
                        attrs: {
                          text: item.name,
                          dashedBorder: true,
                          selected: _vm.checkedSelectedHearAboutUs(item.id),
                          buttonIcon:
                            "img/onboarding/specify_preferences/" + item.img,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleSelectHearAboutUs(item.id)
                          },
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "btn_div" }, [
              _c(
                "button",
                {
                  staticClass: "grupa-blue-btn",
                  on: { click: _vm.handleSubmitData },
                },
                [
                  _vm._v("\n          Confirm\n          "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.spinner,
                        expression: "spinner",
                      },
                    ],
                    staticClass: "button-spinner",
                    staticStyle: { "margin-left": "20px" },
                    attrs: { src: "/img/lightbox/preloader.gif" },
                  }),
                ]
              ),
            ]),
          ],
          2
        ),
      ]),
      _vm.errorMsg ? _c("AlertError", { attrs: { time: "7" } }) : _vm._e(),
      _c(
        "NewHearAboutUsVue",
        _vm._b(
          {},
          "NewHearAboutUsVue",
          {
            getHearAboutUsArray: _vm.getHearAboutUsArray,
            toggleModal: _vm.toggleModal,
            toggleFun: _vm.toggleFun,
            finishSaveToList: _vm.finishSaveToList,
          },
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "content-box", staticStyle: { "margin-bottom": "48px" } },
      [
        _c("div", { staticClass: "check-info" }, [
          _c("img", { attrs: { src: "/img/onboarding/checked-box.svg" } }),
        ]),
        _c("div", { staticClass: "profile-image" }, [
          _c("canvas", {
            attrs: { id: "myCanvas", width: "230", height: "230" },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-div" }, [
      _c("img", { attrs: { src: "/img/onboarding/checked-box.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-div" }, [
      _c("img", { attrs: { src: "/img/onboarding/checked-box.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }