var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dev-onboard" },
    [
      _c("Navbar"),
      _vm.loading
        ? _c("main", [
            _c(
              "div",
              { staticStyle: { left: "50%", right: "0", "margin-top": "10%" } },
              [
                _c("v-progress-circular", {
                  attrs: { size: 30, color: "primary", indeterminate: "" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.loading
        ? _c(
            "div",
            { staticClass: "wrapper_div" },
            [
              _c(
                "div",
                {
                  staticClass: "top_div",
                  style: !_vm.viewProfile ? "justify-content: center;" : "",
                },
                [
                  _vm.viewProfile
                    ? _c(
                        "div",
                        {
                          staticStyle: { width: "30%", cursor: "pointer" },
                          on: { click: _vm.handleGoBack },
                        },
                        [
                          _c("img", {
                            staticStyle: { "margin-right": "9px" },
                            attrs: { src: "/img/onboarding/back-icon.svg" },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#0055a5",
                                "font-size": "14px",
                                "font-family": "Moderat",
                                "font-style": "normal",
                                "font-weight": "500",
                              },
                            },
                            [_vm._v("Go Back")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "progress indicator_progress" }, [
                    _vm._m(0),
                    _c(
                      "a",
                      {
                        staticClass: "indicator",
                        class: _vm.viewProfile == false ? "next" : "dark",
                      },
                      [_c("div")]
                    ),
                  ]),
                ]
              ),
              !_vm.viewProfile
                ? _c("div", { staticClass: "page_wrap" }, [
                    _c("div", { staticClass: "text-wrap" }, [
                      _c("div", { staticClass: "welcome-msg" }, [
                        _vm._v("\n          Welcome to Grupa,\n          "),
                        _vm.currentUser
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.shortenName(
                                      _vm.currentUser.name.split(" ")[0]
                                    )
                                  ) +
                                  "!\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "page-header",
                          staticStyle: { "text-align": "left" },
                        },
                        [
                          _vm._v(
                            "\n          Setup your profile to continue.\n        "
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "grupa-msg" }, [
                        _vm._v(
                          "\n          Grupa is a community of top FAANG experts and the most innovative\n          founders. Showcase your professional profile to your colleagues and\n          partners.\n        "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "input-error" }, [
                      _vm.formError.input
                        ? _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.formError.input) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "linked-wrapper" }, [
                      _c("div", { staticClass: "textbox-label" }, [
                        _vm._v(
                          "\n          Kindly provide your LinkedIn Profile URL\n        "
                        ),
                      ]),
                      _c("div", { staticClass: "linkedin-textbox" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.profile_url,
                              expression: "profile_url",
                            },
                          ],
                          staticClass: "form-control linkedin-text-box",
                          attrs: {
                            type: "text",
                            placeholder: "LinkedIn Profile Url",
                          },
                          domProps: { value: _vm.profile_url },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.profile_url = $event.target.value
                              },
                              _vm.clearErrorMsg,
                            ],
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "btn-connect",
                            on: { click: _vm.handleFetchProfile },
                          },
                          [
                            _vm._v("\n            Get Profile\n            "),
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.spinner,
                                  expression: "spinner",
                                },
                              ],
                              staticClass: "button-spinner",
                              staticStyle: { "margin-left": "10px" },
                              attrs: { src: "/img/lightbox/preloader.gif" },
                            }),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "text-div" }, [
                        _vm._v(
                          "\n          We automatically sync with linkedin to update your professional\n          profile\n        "
                        ),
                      ]),
                      _c("div", { staticClass: "linkedin-details text-div" }, [
                        _vm._v(
                          "\n          Details we will get from Linkedin;\n        "
                        ),
                      ]),
                      _vm._m(1),
                      _vm._m(2),
                      _vm._m(3),
                      _vm._m(4),
                    ]),
                  ])
                : _vm._e(),
              _vm.viewProfile
                ? _c(
                    "UserLinkedinProfile",
                    _vm._b(
                      {},
                      "UserLinkedinProfile",
                      {
                        user_profile: _vm.user_profile,
                        all_tools: _vm.all_tools,
                        teamRoleArray: _vm.teamRoleArray,
                        handleToggleSelected: _vm.handleToggleSelected,
                      },
                      false
                    )
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "indicator dark" }, [_c("div")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-info text-div" }, [
      _c("img", { attrs: { src: "/img/list/list-one.svg" } }),
      _vm._v(" Profile picture\n        "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-info text-div" }, [
      _c("img", { attrs: { src: "/img/list/list-two.svg" } }),
      _vm._v("\n          experience\n        "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-info text-div" }, [
      _c("img", { attrs: { src: "/img/list/list-three.svg" } }),
      _vm._v(" Education\n        "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-info text-div" }, [
      _c("img", { attrs: { src: "/img/list/list-four.svg" } }),
      _vm._v(" Personal details\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }