const shotName = {
  data() {
    return {};
  },
  methods: {
    abbrevateName(name) {
      let arrayValue = name.split(" ");
      if (arrayValue.length > 1) {
        return `${arrayValue[0].charAt(0)}${arrayValue[1].charAt(0)}`;
      } else {
        return arrayValue[0].substring(0, 2);
      }
    },
    shortenName(name) {
      let nameArray = name.split(" ");
      if (nameArray[0].length >= 3) {
        return nameArray[0];
      } else {
        return nameArray[1];
      }
    },
    checkUnderscore(name) {
      let final;
      if (name.includes("_")) {
        let result = name.split("_");
        final = result.join(" & ");
      } else {
        final = name;
      }
      return final;
    },
  },
};

export default shotName;
