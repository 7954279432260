<template>
  <div class="dev-onboard">
    <Navbar />
    <main v-if="loading">
      <div style="left: 50%; right: 0; margin-top: 10%">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </main>
    <div v-if="!loading" class="wrapper_div">
      <div
        class="top_div"
        :style="!viewProfile ? 'justify-content: center;' : ''"
      >
        <div
          v-if="viewProfile"
          @click="handleGoBack"
          style="width: 30%; cursor: pointer"
        >
          <img src="/img/onboarding/back-icon.svg" style="margin-right: 9px" />
          <span
            style="
              color: #0055a5;
              font-size: 14px;
              font-family: Moderat;
              font-style: normal;
              font-weight: 500;
            "
            >Go Back</span
          >
        </div>
        <div class="progress indicator_progress">
          <!-- -->
          <a class="indicator dark">
            <div></div>
          </a>
          <a class="indicator" :class="viewProfile == false ? 'next' : 'dark'">
            <div></div>
          </a>
        </div>
      </div>
      <div class="page_wrap" v-if="!viewProfile">
        <div class="text-wrap">
          <div class="welcome-msg">
            Welcome to Grupa,
            <span v-if="currentUser">
              {{ shortenName(currentUser.name.split(" ")[0]) }}!
            </span>
          </div>
          <div class="page-header" style="text-align: left">
            Setup your profile to continue.
          </div>
          <div class="grupa-msg">
            Grupa is a community of top FAANG experts and the most innovative
            founders. Showcase your professional profile to your colleagues and
            partners.
          </div>
        </div>
        <div class="input-error">
          <span v-if="formError.input">
            {{ formError.input }}
          </span>
        </div>
        <div class="linked-wrapper">
          <div class="textbox-label">
            Kindly provide your LinkedIn Profile URL
          </div>
          <div class="linkedin-textbox">
            <!-- {{ currentUser.linkedin_profile }}
            {{profile_url}} -->
            <input
              v-model="profile_url"
              class="form-control linkedin-text-box"
              type="text"
              placeholder="LinkedIn Profile Url"
              @input="clearErrorMsg"
            />
            <button @click="handleFetchProfile" class="btn-connect">
              Get Profile
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 10px"
                class="button-spinner"
                v-show="spinner"
              />
            </button>
            <!-- handleFetchProfile -->
          </div>
          <div class="text-div">
            We automatically sync with linkedin to update your professional
            profile
          </div>
          <div class="linkedin-details text-div">
            Details we will get from Linkedin;
          </div>
          <div class="list-info text-div">
            <img src="/img/list/list-one.svg" /> Profile picture
          </div>
          <!-- <div class="list-info text-div">
            <img src="/img/list/list-two.svg" /> Skillsets
          </div> -->
          <div class="list-info text-div">
            <img src="/img/list/list-two.svg" />
            experience
          </div>
          <div class="list-info text-div">
            <img src="/img/list/list-three.svg" /> Education
          </div>
          <div class="list-info text-div">
            <img src="/img/list/list-four.svg" /> Personal details
          </div>
        </div>
      </div>
      <UserLinkedinProfile
        v-if="viewProfile"
        v-bind="{
          user_profile,
          all_tools,
          teamRoleArray,
          handleToggleSelected,
        }"
      />
    </div>
  </div>
</template>

<script>
import countryFinder from "country-finder";
import { mapActions, mapState } from "vuex";
import shortName from "@/mixins/shortName";
import mixPanel from "@/mixins/mixPanel";
import Navbar from "@/components/Navbar/onboarding_navbar";
import {
  getLinkedinProfile,
  fetchToolsAndFrameWorks,
  devTeamRoles,
  linkedinScrapper,
} from "@/api";
import UserLinkedinProfile from "./userLinkedinProfile.vue";
export default {
  components: {
    Navbar,
    UserLinkedinProfile,
  },
  mixins: [shortName, mixPanel],
  data: () => ({
    loading: true,
    teamRoleArray: [],
    all_tools: [],
    profile_url: "",
    isEditProfile: false,
    spinner: false,
    disable_btn: false,
    viewProfile: false,
    user_profile: null,
    formError: {
      input: "",
    },
  }),
  mounted() {
    this.profile_url = this.currentUser.linkedin_profile;
    if (this.currentUser.linkedin_profile != "") {
      this.handleFetchProfile();
    }
    //alert(this.profile_url)

    //currentUser.linkedin_profile
  },
  created() {
    this.fetchTeamRoles();
    this.getToolsAndFrameWorks();
    this.getUserLinkedinProfile();
  },
  methods: {
    ...mapActions("devOnboarding", ["setLinkedinProfile"]),

    fetchTeamRoles() {
      devTeamRoles().then((response) => {
        if (response.data.status == 1) {
          this.teamRoleArray = response.data.data;
        }
      });
    },
    getToolsAndFrameWorks() {
      fetchToolsAndFrameWorks()
        .then((response) => {
          if (response.data.status == 1) {
            //this.all_tools = response.data.data;
            this.all_tools = response.data.data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);

          }
        })
        .catch((err) => {});
    },
    validateURL(string) {
      if (/(http(s?)):\/\//i.test(string)) {
        return true;
      }
      return false;
    },
    handleFetchProfile() {
      if (this.profile_url == "") {
        this.formError.input = "Enter a valid Linkedin profile";
      }

      let url = "";

      if (this.validateURL(this.profile_url)) {
        url = this.profile_url;
      } else {
        url = "https://" + this.profile_url;
      }

      // alert(url);
      if (this.profile_url && this.isEditProfile) {
        this.spinner = true;
        // let payload = { profileUrl: this.profile_url };
        let payload = { profileUrl: url };

        this.fetchLinkedinProfile(payload);
        // this.scrapUserProfile(payload);
      }
      if (this.profile_url && this.userLinkedinData && !this.isEditProfile) {
        this.user_profile = this.userLinkedinData;
        this.viewProfile = true;
      }
    },
    handleProfile() {
      this.$router.push({ name: "tools_lang" });
    },
    handleGoBack() {
      this.viewProfile = false;
    },
    fetchLinkedinProfile(payload) {
      getLinkedinProfile(payload)
        .then((response) => {
          if (response.data.status) {
            let { experiences, education } = response.data.data;
            let experiencesWithFixedMonths = experiences;
            if (experiences.length) {
              experiencesWithFixedMonths = experiences.map((e) => {
                // adjust month for javascript since they are 0 based
                if (e.starts_at && e.starts_at.month) {
                  e.starts_at.month--;
                }
                if (e.ends_at && e.ends_at.month) {
                  e.ends_at.month--;
                }
                return e;
              });
            }
            response.data.data.experiences = this.addProperty(
              experiencesWithFixedMonths
            );
            response.data.data.education = this.addProperty(education);
            this.viewProfile = true;
            this.user_profile = response.data.data;
            payload.data = response.data.data;
            this.setLinkedinProfile(payload);
            this.mixPanelTrack({
              event: "FetchLinkedinProfile",
              email: "",
              linkedinUrl: payload.profileUrl,
            });
          }
          this.$developConsole(response.data, "Api response");
        })
        .catch((error) => {
          let errorMsg;
          if (error.response.data) {
            errorMsg = JSON.parse(error.response.data.message);
            this.formError.input = errorMsg.description;
          }
          this.mixPanelTrack({
            event: "LinkedinProfileFailed",
            email: "",
            status: errorMsg ? errorMsg : "Failed",
            linkedinUrl: payload.profileUrl,
          });
        })
        .finally(() => {
          this.disable_btn = false;
          this.spinner = false;
          this.loading = false;
        });
    },
    clearErrorMsg() {
      this.formError.input = "";
      this.isEditProfile = true;
    },
    getUserLinkedinProfile() {
      // No linkedin profile URL
      if (!this.userLinkedinURL && !this.userLinkedinData) {
        this.viewProfile = false;
        this.loading = false;
      }
      // There exist profile URL but no data fetched
      if (this.userLinkedinURL && !this.userLinkedinData) {
        let currentProfileURL = this.userLinkedinURL;
        let linkedinProfileURL;
        // check for link url in user profile
        if (currentProfileURL.includes("https://www.linkedin.com")) {
          linkedinProfileURL = currentProfileURL;
        } else {
          linkedinProfileURL = `https://www.linkedin.com/in/${currentProfileURL}`;
        }
        this.profile_url = linkedinProfileURL;
        let payload = { profileUrl: this.profile_url };
        // this.scrapUserProfile(payload);
        this.fetchLinkedinProfile(payload);
      }
      // User Linkedin data already fetched
      console.log(this.userLinkedinData)
      if (this.userLinkedinData) {
        this.user_profile = JSON.parse(JSON.stringify(this.userLinkedinData));
        this.profile_url = this.userLinkedinURL;
        this.viewProfile = true;
        this.loading = false;
      }
    },
    addProperty(data) {
      if (data.length > 0) {
        return data.map((item, index) => {
          return { ...item, selected: true, id: index + 1 };
        });
      } else {
        return [];
      }
    },
    handleToggleSelected(id, category) {
      const itemIndex = this.user_profile[category].findIndex(
        (item) => item.id == id
      );
      this.user_profile[category][itemIndex].selected =
        !this.user_profile[category][itemIndex].selected;
    },
    scrapUserProfile(data) {
      const { profileUrl } = data;
      let payload = { profile_url: profileUrl };
      linkedinScrapper(payload)
        .then((response) => {
          if (response.status == 200 && response.data.result) {
            this.formatScrapperData(response.data.result);
            this.spinner = false;
          } else {
            // fallback to other approach
            this.fetchLinkedinProfile(data);
          }
        })
        .catch((err) => {
          let errorMsg;
          if (err.response.data && err.response.data.message) {
            let errorMsg = err.response.data.message;
            this.formError.input = errorMsg;
          }
          // fallback to other approach
          this.fetchLinkedinProfile(data);
          this.mixPanelTrack({
            event: "LinkedinProfileFailed",
            email: "",
            status: errorMsg ? errorMsg : "Failed",
            linkedinUrl: profileUrl,
          });
        })
        .finally(() => {
          this.disable_btn = false;
          this.spinner = false;
          this.loading = false;
        });
    },
    formatScrapperData(data) {
      let { education, positions, linkedinUrl, countryCode } = data;
      let userEducation = education.map((degree) => {
        let { dateRange } = degree;
        let startYear, startMonth, endYear, endMonth;
        if (dateRange) {
          const { end, start } = dateRange;
          startYear = start ? start.year : null;
          startMonth = start ? start.month : null;
          endYear = end ? end.year : null;
          endMonth = end ? end.month : null;
        }
        return {
          ...degree,
          degree_name: degree.degree,
          field_of_study: degree.field,
          starts_at: { year: startYear, month: startMonth },
          ends_at: { year: endYear, month: endMonth },
        };
      });

      let userExperiences = positions.map((experience) => {
        let { dateRange } = experience;
        let startYear, startMonth, endYear, endMonth;
        if (dateRange) {
          const { end, start } = dateRange;
          startYear = start ? start.year : null;
          startMonth = start ? start.month : null;
          endYear = end ? end.year : null;
          endMonth = end ? end.month : null;
        }
        return {
          ...experience,
          starts_at: { year: startYear, month: startMonth },
          ends_at: { year: endYear, month: endMonth },
        };
      });
      if (countryCode) {
        let countryDetails = countryFinder.byCountryCode(countryCode);
        data.country_full_name = countryDetails.name;
      }
      let profileData = {
        ...data,
        experiences: this.addProperty(userExperiences),
        education: this.addProperty(userEducation),
      };
      this.viewProfile = true;
      this.user_profile = profileData;
      let payload = { profileUrl: linkedinUrl };
      payload.data = profileData;
      this.setLinkedinProfile(payload);
      this.mixPanelTrack({
        event: "FetchLinkedinProfile",
        email: "",
        linkedinUrl: payload.profileUrl,
      });
      this.viewProfile = true;
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("devOnboarding", [
      "registrationToken",
      "userLinkedinData",
      "userLinkedinURL",
    ]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.wrapper_div {
  margin-bottom: 177px;
}
.top_div {
  display: flex;
  margin-top: 46px;
  /*  */
}
.page_wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.text-wrap {
  width: 410px;
  /* padding-bottom: 150px; */
}

.indicator_progress {
  display: flex;
  justify-content: center;
  margin: 0px;
  width: 37%;
}
.page-header {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
  margin: 16px 0px;
}
.welcome-msg span {
  font-weight: bold;
}
.welcome-msg {
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
  margin-top: 65px;
  text-align: left;
}

.grupa-msg {
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: #979da5;
  margin-top: 16px;
  text-align: left;
}
.linkedin-text-box {
  width: 260px;
  margin-bottom: 0;
}
.linked-wrapper {
  /* margin-top: 48px; */
  width: 410px;
}
.linked-wrapper a {
  text-decoration: none;
}
.input-error {
  width: 410px;
  margin-top: 16px;
  height: 16px;
  color: red;
  font-size: 14px;
  margin-bottom: 16px;
  text-align: left;
}
.connect-btn {
  background: #0288d1;
  box-shadow: 1px 2px 5px rgba(21, 23, 26, 0.2);
  border-radius: 4px;
  padding: 9px;
  width: 410px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.connect-btn img {
  margin-right: 80px;
}
.text-div {
  margin-top: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: left;
}
.linkedin-details {
  margin-top: 48px;
}
.textbox-label {
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
  text-align: left;
}
.linkedin-textbox {
  display: flex;
  margin-top: 12px;
}
.btn-connect {
  background: #0781f2;
  box-shadow: 1px 2px 5px rgba(21, 23, 26, 0.2);
  border-radius: 4px;
  padding: 9px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-left: 4px;
  height: 50px;
  color: #ffffff;
  width: 148px;
}
.button-div {
  margin-top: 48px;
  display: flex;
  justify-content: center;
}
</style>
